import React, { useState } from 'react'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { BrowserView, MobileView } from 'react-device-detect'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const Header = (props) => {
  const breakpoints = useBreakpoint()
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [navbarBrowserViewOpen, setNavbarBrowserViewOpen] = useState(false)
  const {languages, originalPath} = useI18next()
  const {t, i18n} = useTranslation()
  const curr = i18n.language

  return (
    <React.Fragment>
    <header>
      <nav className="bg-blue-900">
      <ul className="flex items-center justify-end flex-wrap w-full my-0 px-0">
      <li className="flex-1 lg:mt-0 ml-2 my-0 text-white text-sm logotype"><Link to="/">{t('Home')}</Link></li>
      <li className="lg:mt-0 text-teal-200 my-0 text-xs mr-2">{t('Language:')}</li>
        {languages.map((lng) => (
          <li key={lng} className="my-0">
            <Link className={`block border-l border-blue-700 py-1 px-4 text-xs text-white hover:text-gray-100 ${curr === lng ? "bg-blue-700 hover:bg-blue-700" : "bg-blue-800 hover:bg-blue-700"}`} to={originalPath} language={lng}>{lng}</Link>
          </li>
        ))}
      </ul>
      </nav>

      <MobileView>
        <div className="bg-blue-700 p-3">
          <button
            onClick={() => setNavbarOpen(!navbarOpen)}
           className="flex items-center p-1 text-white hover:text-white hover:color-blue-700">
          { navbarOpen ? ( <svg className="fill-current h-4 w-4" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg> )
          : ( <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg> ) }
          </button>
        </div>
        {navbarOpen &&
          <nav className={`flex items-center justify-between flex-wrap bg-blue-700 p-6`}>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              <div className="text-sm lg:flex-grow">
                <ul className="block w-full">
                  <li><Link to="/#who" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                  {t('Who is Dexter?')}
                  </Link></li>
                  <li><Link to="/#what" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                    {t('What does Dexter do?')}
                  </Link></li>
                  <li><Link to="/#aifndtn" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                    {t('Who created Dexter?')}
                  </Link></li>
                  <li><Link to="/AI" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">{t('AI & Dexter Education')}</Link></li>
              </ul>
            </div>
              <div>
                <Link to="/#register" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">{t('Pre-Register')}</Link>
              </div>
            </div>
          </nav>
          }
        </MobileView>

        <BrowserView>
        { breakpoints.lg &&
        <div className="bg-blue-700 p-3">
          <button
            onClick={() => setNavbarBrowserViewOpen(!navbarBrowserViewOpen)}
           className="flex items-center p-1 text-white hover:text-white hover:color-blue-700">
          { navbarBrowserViewOpen ? ( <svg className="fill-current h-4 w-4" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg> )
          : ( <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg> ) }
          </button>
        </div>
        }

        { breakpoints.lg && navbarBrowserViewOpen &&
          <nav className={`flex items-center justify-between flex-wrap bg-blue-700 p-6`}>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              <div className="text-sm lg:flex-grow">
                <ul className="block w-full">
                  <li><Link to="/#who" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                  {t('Who is Dexter?')}
                  </Link></li>
                  <li><Link to="/#what" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                    {t('What does Dexter do?')}
                  </Link></li>
                  <li><Link to="/#aifndtn" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                    {t('Who created Dexter?')}
                  </Link></li>
                  <li><Link to="/AI" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">{t('AI & Dexter Education')}</Link></li>
              </ul>
            </div>
              <div>
                <Link to="/#register" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">{t('Pre-Register')}</Link>
              </div>
            </div>
          </nav>
        }

        { breakpoints.xl &&
        <nav className={`flex items-center justify-between flex-wrap bg-blue-700 p-3`}>
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="text-sm lg:flex-grow">
              <ul className="block w-full tracking-wider">
                <li className="inline"><Link to="/#who" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                {t('Who is Dexter?')}
                </Link></li>
                <li className="inline"><Link to="/#what" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                  {t('What does Dexter do?')}
                </Link></li>
                <li className="inline"><Link to="/#aifndtn" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                  {t('Who created Dexter?')}
                </Link></li>
                <li className="inline"><Link to="/AI" className="block mt-4 mr-5 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">{t('AI & Dexter Education')}</Link></li>
            </ul>
          </div>
            <div>
              <Link to="/#register" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">{t('Pre-Register')}</Link>
            </div>
          </div>
        </nav>
      }
      </BrowserView>
    </header>
    </React.Fragment>
  )
}

export default Header
