import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Header from './Header'

export default function Layout({ children }) {
  const {t} = useTranslation()
  return (
    <React.Fragment>
      <Header />
      {children}
      <footer className="flex items-center flex-wrap bg-blue-700 p-6">
          <span className="text-white">&copy; { new Date().getFullYear() } {t('Dexter Memory Program')} </span>
          <a className="text-white ml-5" href="mailto:info@dexterprogram.com"><u>info@dexterprogram.com</u></a>
          <a className="text-white mx-5" href="Dexter_Neuro_Learning_Systems_Privacy_Policy.pdf" target="_blank" title="Privacy Policy"><u>Privacy Policy</u></a>
      </footer>
    </React.Fragment>
  )
}
